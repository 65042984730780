import React from 'react';
import { Link, Params, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { BiHomeAlt, BiChevronRight } from 'react-icons/bi';
import { RouteItem } from '../../types';
import styles from './RouteBreadcrumb.module.scss';

const ROOT_PATH = '/';

export type BreadcrumbProps = {
	crumbList: RouteItem[];
};

type RouterLabelProps = {
	params: Readonly<Params>;
	label: string;
};

const RouterLabel = ({ params, label }: RouterLabelProps) => {
	let newLabel = label;
	Object.keys(params).forEach((p) => {
		newLabel = newLabel.replaceAll(`:${p}`, params[p]!);
	});
	return <span>{newLabel}</span>;
};

export const RouteBreadcrumb = ({ crumbList }: BreadcrumbProps) => {
	const params = useParams();
	return (
		<>
			<div className={styles.container}>
				<Link key="home" className={styles.clearAnchor} to={ROOT_PATH}>
					<BiHomeAlt
						className={classnames([styles.menuItem, styles.breadcrumbHome])}
					/>
				</Link>
				{crumbList
					.filter((routeItem: RouteItem) => routeItem.uri !== ROOT_PATH)
					.map((routeItem: RouteItem, index: number) => (
						<div key={`breadcrumb${index}`} className={styles.menuItem}>
							<BiChevronRight className={styles.arrow} />
							<Link className={styles.clearAnchor} to={routeItem.uri}>
								<RouterLabel params={params} label={routeItem.label} />
							</Link>
						</div>
					))}
			</div>
		</>
	);
};
