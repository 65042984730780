import axios from './interceptors';
import { BASE_URL } from './constants';

/**
 * Calls the API to retrieve the device list.
 * @param query Query string to filter the devices.
 * @returns a list of devices
 */
export const listDevice = (query?: string) => {
	if (!query) {
		query = '*';
	}
	return axios
		.get(`${BASE_URL}/v1/devices?query=${query}`)
		.then((response) => {
			return response.data.devices;
		})
		.catch((error) => {
			throw error;
		});
};
