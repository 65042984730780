import { Button, Modal, ModalProps } from '@easerill/pixida-group-ui';
import { TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
/**
 * Props for the AssignDevicesToCustomer component.
 */
export type FilterDevicesProps = {
	onCancel: () => void;
	// eslint-disable-next-line no-unused-vars
	onSearch: (serialNumbers: string) => void;
	filter?: string;
} & Omit<ModalProps, 'title' | 'onClose'>;

/**
 * Modal used to filter devices by serial number
 * @param {FilterDevicesProps} props - The props for the FilterDevices component.
 * @returns The FilterDevices component.
 */
const FilterDevices = ({
	open,
	filter,
	onCancel,
	onSearch,
}: FilterDevicesProps) => {
	const [serialNumbers, setSerialNumbers] = useState('');
	const [firstInvalidSN, setFirstInvalidSN] = useState<string | null>(null);
	const SERIAL_NUMBER_PATTERN =
		/^(?<productType>[A-Za-z0-9]{2})-(?<hardwareVariant>[A-Za-z0-9]{2})-(?<batchNumber>[A-Fa-f0-9]{2})-(?<deviceId>(?!0+$)\d{7})$/;
	const filterInputRef = React.useRef<any>(null);

	useEffect(() => {
		if (filter) {
			setSerialNumbers(filter);
		}
	}, [filter]);

	/**
	 * Handles the click event when the user cancels the filtering process.
	 */
	const handleCancelClick = () => {
		if (onCancel) {
			onCancel();
		}
	};

	/**
	 * Validate a serial number
	 * @param serialNumber serial number to be validated
	 * @returns a boolean representing valid/invalid
	 */
	const validateSerialNumber = (serialNumber: string | null | undefined) => {
		if (serialNumber == null) {
			return false;
		}
		return SERIAL_NUMBER_PATTERN.test(serialNumber);
	};

	/**
	 *
	 * @param event string with the serial numbers
	 */
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSerialNumbers(event.target.value);
	};

	/**
	 * Handles the filtering when user click in search button
	 */
	const handleSearch = () => {
		if (onSearch) {
			onSearch(serialNumbers);
		}
	};

	/**
	 * Splits a string into lines and calculates the start and end positions of each line
	 * @param text The input string containing multiple lines separated by '\n'
	 * @returns An array of objects, where each object contains the content, start position, and end position of each line
	 */
	const getLinesWithPositions = (text: string) => {
		const lines = text.split('\n');
		let currentPosition = 0;
		return lines.map((line) => {
			const startPos = currentPosition;
			const endPos = startPos + line.length;
			currentPosition = endPos + 1; // Skip newline character
			return { content: line.trim(), startPos, endPos };
		});
	};

	/**
	 * Validates each serial number and executes de search
	 */
	const validateAndSearch = () => {
		const lines = getLinesWithPositions(serialNumbers);
		for (const [idx, line] of lines.entries()) {
			if (line.content !== '' && !validateSerialNumber(line.content)) {
				setFirstInvalidSN(line.content);
				if (filterInputRef.current) {
					filterInputRef.current.setSelectionRange(line.startPos, line.endPos);

					filterInputRef.current.focus();

					const lineHeight = filterInputRef.current.scrollHeight / lines.length;
					filterInputRef.current.scrollTop = lineHeight * idx + 1;
					return;
				}
			}
		}
		setFirstInvalidSN(null);
		handleSearch();
	};

	return (
		<Modal open={open} onClose={handleCancelClick} title="Filter Devices">
			<TextField
				multiline={true}
				rows={15}
				type="text"
				onChange={handleChange}
				value={serialNumbers}
				id="serialNumber"
				label="Serial Numbers"
				placeholder="Enter serial numbers, one per line"
				autoFocus
				error={firstInvalidSN ? true : false}
				helperText={
					firstInvalidSN ? `Invalid serial number: ${firstInvalidSN}` : ' '
				}
				inputRef={filterInputRef}
			/>
			<div style={{ marginLeft: 'auto', display: 'flex', gap: '16px' }}>
				<Button
					variation="info"
					text="Cancel"
					onClick={handleCancelClick}
					startIcon={<HighlightOffIcon />}
				/>
				<Button
					variation="default"
					onClick={validateAndSearch}
					startIcon={<FilterAltOutlinedIcon />}
					text="Apply"
				/>
			</div>
		</Modal>
	);
};

export default FilterDevices;
