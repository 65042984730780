import { Event } from '../../../../packages/types/event';

/**
 * Represents the structure of an event for rendering in a table format.
 */
export type EventForTable = {
	id: string;
	date: string;
	title: string;
	description: string;
	serialNumber: string;
	fileKeys: string[] | undefined;
	[name: string]: string | string[] | undefined;
};

/**
 *
 */
export class EventTypeForTable {
	public readonly eventsList: EventForTable[];

	/**
	 * Creates an instance of EventTypeForTable.
	 * @param eventsList - List of events to be formatted for rendering in a table.
	 */
	constructor(eventsList: Event[]) {
		this.eventsList = this.EventsListToDeviceListForTable(eventsList);
	}

	/**
	 * Converts a list of events to a list of events formatted for rendering in a table.
	 * @param eventsList - List of events to be formatted.
	 * @returns List of events formatted for rendering in a table.
	 */
	private EventsListToDeviceListForTable(eventsList: Event[]): EventForTable[] {
		const newEventForTableList: EventForTable[] = [];
		for (let i = 0; i < eventsList.length; i++) {
			newEventForTableList.push(this.EventToEventForTable(eventsList[i]));
		}
		return newEventForTableList;
	}

	/**
	 * formats date
	 * @param date date to be formatted
	 * @returns formatted datettime
	 */
	private formatDate(date: Date): string {
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
		const strTime = `${hours}:${minutesStr}`;
		return `${
			date.getMonth() + 1
		}.${date.getDate()}.${date.getFullYear()}  ${strTime}`;
	}

	/**
	 * Converts a single event to a event formatted for rendering in a table.
	 * @param event The event to be formatted
	 * @returns Device formatted for rendering in a table.
	 */
	private EventToEventForTable(event: Event): EventForTable {
		const newEventForTable: EventForTable = {
			id: event.timestamp.toString(),
			serialNumber: event.serialNumber,
			date: event.timestamp ? this.formatDate(new Date(event.timestamp)) : '',
			title: event.title,
			description: event.description || '',
			fileKeys: event.files?.map((element) => element.key),
		};

		return newEventForTable;
	}
}
