import React, { ReactNode, useRef, useState } from 'react';
import MuiTextField, {
	TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { IconButton as MuiIconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../Button';

export type TextFieldInputType = 'text' | 'password' | 'number' | 'file';

export type TextFieldProps = {
	/** The component identifier. */
	id: string;
	/** The label to be displayed. */
	label: string;
	/** The text value of this component. Ignored if __type__ is __'file'__. */
	value?: string;
	/** The type of the text field. */
	type?: TextFieldInputType;
	/** Whether this field is required. If set to __true__, an asterisk is rendered after the label text */
	required?: boolean;
	/** Whether this field is disabled. */
	disabled?: boolean;
	/** Whether this field should take up the full width of its container. */
	fullWidth?: boolean;
	/** The text displayed in the input before the user enters a value. Ignored if __type__ is __'file'__. */
	placeholder?: string;
	/** The helper text to be displayed. */
	helperText?: string;
	/** The error text to be displayed in case of error. */
	errorText?: string;
	/** Whether this field should support multiple lines of text. */
	multiline?: boolean;
	/** If __multiline__ is set to __true__, how many lines of text should be displayed at a time. */
	rows?: number;
	/** The horizontal margin (left and right) in __em__ to be applied to the component. */
	horizontalMargin?: number;
	/** An icon to display at the start of the text field. Ignored if __type__ is __'file'__ or __'password'__. */
	startIcon?: ReactNode;
	/** An icon to display at the end of the text field. Ignored if __type__ is __'file'__ or __'password'__. */
	endIcon?: ReactNode;
	/** A callback function executed when the text changes. */
	onChange?: (text: string) => void;
	/** A callback function executed when one or more files are selected. If desired, the files contents can be read with the functions __readFileAsTextPromise__, __readFileAsArrayBufferPromise__ or __readFileAsDataURLPromise__. */
	onFileSelected?: (files: File[]) => void;
	/** The file kinds accepted, according to HTML standards. Requires __type__ to be __file__. */
	fileAccept?: string;
	/** Whether multiple files can be selected. Requires __type__ to be __file__. */
	fileMultiple?: boolean;
	/** The names of optional CSS classes to be added to the component. */
	className?: string;
	/** Maximum length (number of characters) of value. */
	maxLength?: number;
	/** The size of the component. */
	size?: 'medium' | 'small';
};

/**
 * A text field component that allows the user to enter text and upload file(s).
 */
export const TextField = ({
	id,
	label,
	value,
	type = 'text',
	required = false,
	disabled = false,
	fullWidth = false,
	placeholder,
	helperText,
	errorText,
	multiline,
	rows,
	horizontalMargin = 0,
	startIcon,
	endIcon,
	onChange,
	onFileSelected,
	fileAccept,
	fileMultiple = false,
	className,
	maxLength,
	size = 'medium',
}: TextFieldProps) => {
	const [fileInputUniqueId] = useState<string>(uuidv4());
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [files, setFiles] = useState<File[]>([]);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(event?.target?.value);
		}
	};

	const handleOnFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileList: FileList | null = event?.target?.files;
		const uploadedFiles: File[] = [];
		if (fileList) {
			for (let i = 0; i < fileList.length; i++) {
				uploadedFiles.push(fileList[i]);
			}
		}
		setFiles(uploadedFiles);
		if (onFileSelected) {
			onFileSelected(uploadedFiles);
		}
	};

	const handleOnClickShowPassword = () => {
		setShowPassword((prevShowPassword: boolean) => !prevShowPassword);
	};

	const handleOnMouseDownShowPassword = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
	};

	const handleTriggerSelectFile = (inputElementId: string) => {
		document.getElementById(inputElementId)?.click();
	};

	/**
	 * Using a separate object for the props because of the more complex logic bellow
	 */
	const muiTextFieldProps: MuiTextFieldProps = {
		id: id,
		type: type || 'text',
		label: label,
		value: value,
		onChange: handleOnChange,
		required: required,
		disabled: disabled,
		fullWidth: fullWidth,
		placeholder: placeholder,
		helperText: (errorText ? errorText : helperText) || undefined,
		error: !!errorText,
		multiline: multiline,
		rows: rows,
		variant: 'outlined',
		style:
			!!horizontalMargin && horizontalMargin > 0
				? { margin: `0 ${horizontalMargin}em` }
				: {},
		InputProps: {},
		...(maxLength && { inputProps: { maxLength } }),
		className: className,
		size,
	};

	if (type === 'password') {
		if (showPassword) {
			muiTextFieldProps.type = 'text';
		}
		muiTextFieldProps.InputProps = {
			endAdornment: (
				<MuiIconButton
					aria-label="toggle password visibility"
					onClick={handleOnClickShowPassword}
					onMouseDown={handleOnMouseDownShowPassword}
					edge="end"
				>
					{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
				</MuiIconButton>
			),
		};
	}

	if (type === 'file') {
		muiTextFieldProps.type = 'text';
		muiTextFieldProps.value =
			files.map((file: File) => file.name).join(', ') || '';
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		muiTextFieldProps.onChange = () => {};
		muiTextFieldProps.disabled = true;
		muiTextFieldProps.InputProps = {
			endAdornment: (
				<>
					<label htmlFor={fileInputUniqueId}>
						<input
							id={fileInputUniqueId}
							type="file"
							accept={fileAccept ? fileAccept : undefined}
							multiple={fileMultiple}
							onChange={handleOnFileSelected}
							style={{ display: 'none' }}
						/>
						<Button
							text="Select file"
							size="small"
							onClick={() => handleTriggerSelectFile(fileInputUniqueId)}
						/>
					</label>
				</>
			),
		};
	}

	if (startIcon) {
		muiTextFieldProps.InputProps = {
			startAdornment: startIcon,
		};
	}

	if (endIcon) {
		if (type !== 'password' && type !== 'file') {
			muiTextFieldProps.InputProps = {
				...muiTextFieldProps.InputProps,
				endAdornment: endIcon,
			};
		}
	}

	return (
		<>
			<MuiTextField {...muiTextFieldProps} />
		</>
	);
};
