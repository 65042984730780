import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { Customer } from '@farm/types/customer';
import { customersSlice } from '../../redux/Customers';

/**
 * The object returned by `useStateCustomer`
 */
type ReturnType = {
	customers: Customer[] | null | undefined;
	// eslint-disable-next-line no-unused-vars
	setCustomers: (customers: Customer[] | null) => void;
};

/**
 * Custom hook for handling customer data in the Redux state.
 * @returns See `ReturnType`.
 */
const useStateCustomer = (): ReturnType => {
	const dispatch = useDispatch();

	const customers = useSelector<RootState, Customer[] | null | undefined>(
		(state: RootState) => state.customer.customers,
	);

	/**
	 * Set the customers in the Redux store.
	 * @param customers - An array of Customer objects or null if there are no devices.
	 */
	const setCustomers = (customers: Customer[] | null) => {
		dispatch(customersSlice.actions.setCustomers(customers));
	};

	return {
		customers,
		setCustomers,
	};
};

export default useStateCustomer;
