import React, { useEffect } from 'react';
import { RouteProps, useNavigate } from 'react-router-dom';
import { AUTH_BASE_URL } from '../../api/constants';
import Layout from '../Layout/Layout';
import { Routes, RouteItem } from '@easerill/pixida-group-ui';

/**
 * Properties of a protected route
 */
declare type IProtectedRoute = RouteProps & {
	currentRoute: RouteItem;
	userRoles: string[];
	routes: Routes;
	userAccount?: string;
	breadcrumbList: RouteItem[];
};

// TODO Replace this?
/**
 *
 */
type RedirectProps = {
	to: string;
};
/**
 * Redirects `to` a given URL
 * @param props Redirect props objects
 * @param props.to The URL to be redirected to
 * @returns the redirect element.
 */
const Redirect = ({ to }: RedirectProps) => {
	useEffect(() => {
		window.location.href = to;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return null;
};

// TODO Replace this?
/**
 *
 */
type NavigateProps = {
	to: string;
};
/**
 * Redirects `to` a given URL
 * @param props Navigate props objects
 * @param props.to The URL to be navigated to
 * @returns the navigate element.
 */
const Navigate = ({ to }: NavigateProps) => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate(to);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return null;
};

/**
 * Represents a route that cannot be accessed without login.
 * @param props Properties of a protected route.
 * @returns The protected route element.
 */
const ProtectedRoute = (props: IProtectedRoute) => {
	const {
		currentRoute,
		userRoles,
		element,
		userAccount,
		routes,
		breadcrumbList,
	} = props;
	const isSignedIn = Boolean(userRoles.length);

	let protectedElement = null;
	if (
		currentRoute.account &&
		currentRoute.account === userAccount &&
		isSignedIn &&
		currentRoute.roles.some((r) => userRoles.includes(r)) &&
		element
	) {
		protectedElement = element;
	} else if (
		isSignedIn &&
		currentRoute.roles.some((r) => userRoles.includes(r)) &&
		element
	) {
		protectedElement = element;
	} else if (isSignedIn) {
		protectedElement = <Navigate to="/inventory" />;
	} else {
		protectedElement = (
			<Redirect to={`${AUTH_BASE_URL}/login/?redirect_uri=${window.origin}`} />
		);
	}

	return (
		<Layout
			routes={routes}
			userRoles={userRoles}
			breadcrumbList={breadcrumbList}
		>
			{protectedElement}
		</Layout>
	);
};

export default ProtectedRoute;
