// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SQsuzxajiuwnRdhQckAE{width:100%;height:48px;display:flex;flex-direction:row;justify-content:space-between;align-items:center}.ggU_0dmNXMVmo8O0seeC,.Cnmx8jeJwCCWHR_3tQWt,.pZ8ZKghzwaj459kkuJpj,.F1SzGeQuehbWkeXq8smW,.mZxqq2ZVNwELeTl48kqc,.IMLoDpup7Ct6PBdPeOcN,.bognB940PRtCJRdJE6vh,.P2d7VZASQE8r8Y3Nnuyu{display:inline-flex;height:48px}.ggU_0dmNXMVmo8O0seeC{min-width:116px;flex-direction:row;justify-content:flex-start;align-items:center}.ggU_0dmNXMVmo8O0seeC>img{max-height:24px}.mZxqq2ZVNwELeTl48kqc,.IMLoDpup7Ct6PBdPeOcN,.bognB940PRtCJRdJE6vh,.P2d7VZASQE8r8Y3Nnuyu{flex-direction:row;justify-content:center;align-items:center}.D5fi04DVypufmZdjspoS{height:32px;width:1px;margin:0 32px 0 16px;box-sizing:border-box}.IMLoDpup7Ct6PBdPeOcN,.bognB940PRtCJRdJE6vh,.P2d7VZASQE8r8Y3Nnuyu{color:var(--cold-neutral-color);text-transform:uppercase}.bognB940PRtCJRdJE6vh,.P2d7VZASQE8r8Y3Nnuyu{color:var(--primary-color)}.P2d7VZASQE8r8Y3Nnuyu{margin-left:8px !important;color:var(--primary-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SQsuzxajiuwnRdhQckAE",
	"logoContainer": "ggU_0dmNXMVmo8O0seeC",
	"main": "Cnmx8jeJwCCWHR_3tQWt",
	"menu": "pZ8ZKghzwaj459kkuJpj",
	"userInfo": "F1SzGeQuehbWkeXq8smW",
	"separatorContainer": "mZxqq2ZVNwELeTl48kqc",
	"user": "IMLoDpup7Ct6PBdPeOcN",
	"userHighlight": "bognB940PRtCJRdJE6vh",
	"userIcon": "P2d7VZASQE8r8Y3Nnuyu",
	"separator": "D5fi04DVypufmZdjspoS"
};
export default ___CSS_LOADER_EXPORT___;
