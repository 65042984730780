import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { authSlice, User } from '../../redux/Auth';

/**
 * The object returned by `useStateAuth`
 */
type ReturnType = {
	user: User | null | undefined;
	// eslint-disable-next-line no-unused-vars
	setUser: (user: User | null) => void;
};

/**
 * Custom hook for handling Auth data in the Redux state.
 * @returns {ReturnType} See `ReturnType`.
 */
const useStateAuth = (): ReturnType => {
	const dispatch = useDispatch();

	const user = useSelector<RootState, User | null | undefined>(
		(state: RootState) => state.auth.user,
	);

	const setUser = (user: User | null) => {
		dispatch(authSlice.actions.setUser(user));
	};

	return {
		user,
		setUser,
	};
};

export default useStateAuth;
