import { Device } from '../../../../packages/types/device';

/**
 * Represents the structure of a device for rendering in a table format.
 */
export type DeviceForTable = {
	pilabsSerialNumber: string;
	customer: string;
	groups: string;
	assignedVehicle: string;
	lastSeen?: number;
	endpointRegionLabel: string;
	connected?: boolean;
	lastSeenConnected?: number;
	[name: string]: boolean | string | number | undefined;
};

/**
 * Represents a device type formatted for rendering in a table.
 */
export class DeviceTypeForTable {
	public readonly deviceList: DeviceForTable[];

	/**
	 * Creates an instance of DeviceTypeForTable.
	 * @param deviceList - List of devices to be formatted for rendering in a table.
	 */
	constructor(deviceList: Device[]) {
		this.deviceList = this.DeviceListToDeviceListForTable(deviceList);
	}

	/**
	 * Converts a list of devices to a list of devices formatted for rendering in a table.
	 * @param deviceList - List of devices to be formatted.
	 * @returns List of devices formatted for rendering in a table.
	 */
	private DeviceListToDeviceListForTable(
		deviceList: Device[],
	): DeviceForTable[] {
		const newDeviceForTableList: DeviceForTable[] = [];
		for (let i = 0; i < deviceList.length; i++) {
			newDeviceForTableList.push(this.DeviceToDeviceForTable(deviceList[i]));
		}
		return newDeviceForTableList;
	}

	/**
	 * Converts a single device to a device formatted for rendering in a table.
	 * @param device - The device to be formatted.
	 * @returns Device formatted for rendering in a table.
	 */
	private DeviceToDeviceForTable(device: Device): DeviceForTable {
		const newDeviceForTable: DeviceForTable = {
			pilabsSerialNumber: device.pilabsSerialNumber,
			customer: device.customerName || '',
			groups: device.groupNames.join(', '),
			assignedVehicle: device.assignedVehicle || '',
			lastSeen: device.connectivity?.timestamp,
			endpointRegionLabel: device.endpointRegionLabel ?? '',
			connected: device.connectivity?.connected,
			lastSeenConnected: device.connectivity?.timestamp,
			...device.attributes,
		};

		// Add high number to timestamp to elevate connected devices in sort order.
		if (newDeviceForTable.connected && newDeviceForTable.lastSeenConnected) {
			newDeviceForTable.lastSeenConnected =
				newDeviceForTable.lastSeenConnected + 1000000000000;
		}

		return newDeviceForTable;
	}
}
