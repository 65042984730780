import axios from './interceptors';
import { BASE_URL } from './constants';

/**
 * Calls the API to retrieve the events list.
 * @param pilabsSerialNumber serial number of the device
 * @returns a list of events
 */
export const listEvents = (pilabsSerialNumber: string) => {
	return axios
		.get(`${BASE_URL}/v1/devices/${pilabsSerialNumber}/events`)
		.then((response) => {
			return response.data.events;
		})
		.catch((error) => {
			throw error;
		});
};

/**
 * Calls the API to download files .
 * @param pilabsSerialNumber serial number of the device
 * @param eventID id of the event
 * @param filename name of the file
 * @returns a file
 */
export const loadEvent = (
	pilabsSerialNumber: string,
	eventID: string,
	filename: string,
) => {
	return axios
		.get(
			`${BASE_URL}/v1/devices/${pilabsSerialNumber}/events/${eventID}/files/${filename}`,
			{
				responseType: 'text',
			},
		)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
};
