import React, { ReactNode, useEffect, useState } from 'react';
import {
	MaxWidthContainer,
	RouteHeader,
	RouteBreadcrumb,
	RouteItem,
	Routes,
} from '@easerill/pixida-group-ui';
import Footer from '@chaptesi/licenses/components/Footer';
import useStateAuth from '../../hooks/state/useStateAuth';
import useCustomerHive from '../../hooks/api/useCustomerHive';
import styles from './Layout.module.scss';
type LayoutProps = {
	children?: ReactNode;
	routes: Routes;
	userRoles: string[];
	breadcrumbList: RouteItem[];
};

const Layout = ({
	children,
	routes,
	userRoles,
	breadcrumbList,
}: LayoutProps) => {
	const [breadcrumbListItems, setBreadcrumbList] = useState<RouteItem[]>([]);
	const { user } = useStateAuth();
	const { signOut } = useCustomerHive();

	useEffect(() => {
		setBreadcrumbList(breadcrumbList);
	}, [breadcrumbList]);

	return (
		<>
			<div className={styles.container}>
				<RouteHeader
					username={user?.email || 'Guest'}
					routes={routes}
					userRoles={userRoles}
					userMenus={[
						{
							label: 'Logout',
							onClick: () => {
								signOut().then(() => {
									window.location.reload();
								});
							},
						},
					]}
				/>
				<div className={styles.main}>
					<MaxWidthContainer>
						<RouteBreadcrumb crumbList={breadcrumbListItems} />
						<>{children}</>
					</MaxWidthContainer>
				</div>
				<Footer className={styles.footer} />
			</div>
		</>
	);
};

export default Layout;
