import React, { ReactNode } from 'react';
import MuiModal from '@mui/material/Modal';
import { Box, Typography } from '@mui/material';

export type ModalProps = {
	/**
	 * Modal title.
	 */
	title?: NonNullable<React.ReactNode>;
	/**
	 * Modal reference element.
	 */
	children?: ReactNode;
	/**
	 * If true, the component is shown.
	 */
	open: boolean;
	/**
	 * Callback fired when the component requests to be closed.
	 */
	onClose: () => void;
};

const style = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '567px',
	bgcolor: 'background.paper',
	boxShadow: '0px 8px 10px 0px #00000033',
	padding: '24px',
	gap: '16px',
	display: 'flex',
	flexDirection: 'column',
};

/**
 * A component that renders a modal.
 */
export const Modal = ({ children, ...props }: ModalProps) => (
	<MuiModal
		open={props.open}
		onClose={props.onClose}
		autoFocus={false}
		disableRestoreFocus={true}
	>
		<Box sx={style}>
			{props.title ? (
				<Typography
					sx={{
						fontSize: '24px',
						fontWeight: '600',
						lineHeight: '24px',
						letterSpacing: '0.15000000596046448px',
						textAlign: 'left',
					}}
				>
					{props.title}
				</Typography>
			) : null}
			{children}
		</Box>
	</MuiModal>
);
