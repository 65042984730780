import React from 'react';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { render, RenderResult } from '@testing-library/react';
import { authInitialState, authSlice } from './Auth';
import { devicesInitialState, devicesSlice } from './Inventory';
import { eventsInitialState, eventsSlice } from './Events';
import { customersInitialState, customersSlice } from './Customers';
import { regionsInitialState, regionsSlice } from './Regions';

export const rootInitialState: RootState = {
	auth: authInitialState,
	device: devicesInitialState,
	event: eventsInitialState,
	customer: customersInitialState,
	region: regionsInitialState,
};

export const rootReducer = combineReducers({
	auth: authSlice.reducer,
	device: devicesSlice.reducer,
	event: eventsSlice.reducer,
	customer: customersSlice.reducer,
	region: regionsSlice.reducer,
});

/**
 * The root state.
 */
export type RootState = ReturnType<typeof rootReducer>;

/**
 * Supposed to be called by the tests
 * @param component - The component to render.
 * @param initialState - Initial state of the Redux store.
 * @returns A rendered component and additional utilities for testing.
 */
export const renderWithRedux = (
	component: React.ReactNode,
	initialState: RootState = rootInitialState,
): RenderResult => {
	const store: Store = createStore(
		rootReducer,
		initialState,
		applyMiddleware(thunk),
	);
	return render(<Provider store={store}>{component}</Provider>);
};
