// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Kr2sqStXUEr5q73QD27Z{height:48px;display:flex;flex-direction:row;justify-content:flex-start;align-items:center}.u7eYZtYiY96SJhOdtSK9{height:48px;display:inline-block;padding:0 16px;line-height:48px !important;color:var(--cold-neutral-color);text-transform:uppercase;box-sizing:border-box;white-space:nowrap;cursor:pointer}.u7eYZtYiY96SJhOdtSK9:hover{color:var(--primary-color)}.aG0wKhzJlpPq8hzhrt2w{color:var(--primary-color);border-bottom:4px solid var(--primary-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Kr2sqStXUEr5q73QD27Z",
	"menuItem": "u7eYZtYiY96SJhOdtSK9",
	"selected": "aG0wKhzJlpPq8hzhrt2w"
};
export default ___CSS_LOADER_EXPORT___;
