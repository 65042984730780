import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootInitialState, rootReducer } from './index';

const store = createStore(
	rootReducer,
	rootInitialState,
	composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
