// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".do5rrJE1oumzE4sH7852{display:flex;align-items:center;margin-top:0;color:#67808c;font-size:16px;line-height:22px;font-weight:600}.t35SgCZ9x5IagADkwpGg{display:flex;align-items:center}.khHUNs8hvqIS2vtd_B7J,.Jww4OUA0PZ4vLWut8Y1p{height:100%;font-size:20px;text-align:center;vertical-align:middle}.Jww4OUA0PZ4vLWut8Y1p{color:#2b3438;margin:0 16px}.Ibw6802OXXbrHw88OjY8{text-decoration:none;color:inherit}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "do5rrJE1oumzE4sH7852",
	"menuItem": "t35SgCZ9x5IagADkwpGg",
	"breadcrumbHome": "khHUNs8hvqIS2vtd_B7J",
	"arrow": "Jww4OUA0PZ4vLWut8Y1p",
	"clearAnchor": "Ibw6802OXXbrHw88OjY8"
};
export default ___CSS_LOADER_EXPORT___;
