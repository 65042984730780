import React from 'react';
import InventoryList from '../Inventory/Inventory';

/**
 * Renders the inventory component.
 * @returns The inventory component.
 */
const Inventory = () => {
	return <InventoryList />;
};

export default Inventory;
