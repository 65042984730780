// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V_GeI8Xxh5uJgJwu8Pkj{position:sticky;top:0;left:0;width:100%;max-width:100%;min-height:48px;background-color:#fff;box-shadow:0px 4px 16px rgba(0,0,0,.14)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "V_GeI8Xxh5uJgJwu8Pkj"
};
export default ___CSS_LOADER_EXPORT___;
