import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer } from '@farm/types/customer';

/**
 * The state of device list
 */
export type CustomersState = {
	customers: Customer[] | null | undefined;
};

export const customersInitialState: CustomersState = {
	customers: undefined,
};

export const customersSlice = createSlice({
	name: 'customers',
	initialState: customersInitialState as CustomersState,
	reducers: {
		/**
		 * Sets devices in the Redux store state.
		 * @param state - Current state of devices in the Redux store.
		 * @param action - Redux action containing devices data or null.
		 * @returns New state with updated devices information.
		 */
		setCustomers: (
			state: CustomersState,
			action: PayloadAction<Customer[] | null>,
		): CustomersState => ({
			...state,
			customers: action.payload,
		}),
	},
});
