import React from 'react';
import {
	pilabsTheme,
	SnackbarProvider,
	ThemeOptions,
	ThemeProvider,
} from '@easerill/pixida-group-ui';
import AppRoutes from './routes/AppRoutes';

/**
 * The Main app
 * @returns {React.JSX.Element} the main App
 */
function App() {
	const themeOptions: ThemeOptions = pilabsTheme;

	return (
		<>
			<ThemeProvider themeOptions={themeOptions}>
				<SnackbarProvider>
					<AppRoutes />
				</SnackbarProvider>
			</ThemeProvider>
		</>
	);
}

export default App;
