import styles from './Inventory.module.scss';
import {
	ColumnConfig,
	Table,
	SearchTextField,
	pixidaTheme,
	Button,
	Chip,
	Tooltip,
} from '@easerill/pixida-group-ui';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { listDevice } from '../../api/inventory';
import { DeviceTypeForTable, DeviceForTable } from './DeviceTypeForTable';
import { Device } from '@farm/types/device';
import useStateDevice from '../../hooks/state/useStateDevice';
import { BiX } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AssignDevicesToCustomer from '../AssignDevicesToCustomer/AssignDevicesToCustomer';
import { Customer } from '@farm/types/customer';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterDevices from './FilterDevices';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import useStateAuth from '../../hooks/state/useStateAuth';
import { User } from '../../redux/Auth';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

/**
 * Checks if a user has permission based on the required groups.
 * @param {User | null | undefined} user - The user object which include groups.
 * @param {string[]} authorizedGroups - The list of groups that have sufficent permission.
 * @returns {boolean} True if the user belongs to any of the required groups, false otherwise.
 */
export function has_permission(
	user: User | null | undefined,
	authorizedGroups: string[],
): boolean {
	if (!user || !user.groups) {
		return false;
	}
	return authorizedGroups.some((group) => user.groups.includes(group));
}
const tableColumnHeaderConfiguration: ColumnConfig<DeviceForTable>[] = [
	{
		id: 'pilabsSerialNumber',
		label: 'Serial Number',
		align: 'left',
		/**
		 * Function to render the column
		 * @param rowData The row data
		 * @returns The rendered column element.
		 */
		render: (rowData) => {
			return (
				<span
					className={styles.serialNumberCell}
					style={{ color: pixidaTheme.palette.primary.main }}
				>
					<Link to={`/inventory/${rowData.data.pilabsSerialNumber}`}>
						{rowData.data.pilabsSerialNumber}
					</Link>
				</span>
			);
		},
	},
	{
		id: 'customer',
		label: 'Customer',
		align: 'left',
		/**
		 * Function to render the column
		 * @param rowData The row data
		 * @returns The rendered column element.
		 */
		render: (rowData) => {
			return (
				<span className={styles.coldNeutralColor}>{rowData.data.customer}</span>
			);
		},
	},
	{
		id: 'assignedVehicle',
		label: 'Assigned Vehicle',
		align: 'left',
	},
	{
		id: 'groups',
		label: 'Groups',
		align: 'left',
	},
	{
		id: 'lastSeenConnected',
		label: 'Last seen',
		align: 'left',
		/**
		 * Function to render the column
		 * @param rowData The row data
		 * @returns The rendered column element.
		 */
		render: (rowData) => {
			const { connected, lastSeen } = rowData.data;

			if (connected) {
				return <Chip text="online" variation="success" />;
			}

			if (lastSeen) {
				return <span>{timeAgo.format(lastSeen, 'round')}</span>;
			}

			return <Chip text="never" variation="danger" />;
		},
	},
	{
		id: 'endpointRegionLabel',
		label: 'Endpoint Region',
		align: 'left',
		/**
		 * Function to render the column
		 * @param rowData The row data
		 * @returns The rendered column element.
		 */
		render: (rowData) => {
			return (
				<span className={styles.coldNeutralColor}>
					{rowData.data.endpointRegionLabel}
				</span>
			);
		},
	},
];

/**
 * Inventory component displaying a table of devices.
 * @returns The inventory component
 */
const Inventory = () => {
	const [columnsDefinition, setColumnsDefinition] = useState<
		ColumnConfig<DeviceForTable>[]
	>(tableColumnHeaderConfiguration);
	const [deviceTypeForTable, setDeviceTypeForTable] =
		useState<DeviceTypeForTable>(new DeviceTypeForTable([]));
	const [isLoading, setIsLoading] = useState(true);
	const [searchError, setSearchError] = useState<string | undefined>(undefined);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const searchRef = useRef<any>(null);
	const [assignDevicesOpen, setAssignDevicesOpen] = useState(false);
	const [filterDevicesOpen, setFilterDevicesOpen] = useState(false);
	const [selectedSerialNumbers, setSelectedSerialNumbers] = useState<
		readonly string[]
	>([]);
	const { devices, setDevices } = useStateDevice();
	const [filter, setFilter] = useState('');
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const tableRef = React.useRef<any>(null);
	const { user } = useStateAuth();

	/**
	 * Create the device table
	 * @param data the list of the devices
	 * @param user the loged in user
	 */
	function createDisplayTable(data: Device[], user: User | null | undefined) {
		const extendedData = data.map((device) => ({
			...device,
			user_groups: user?.groups || [],
		}));
		setDeviceTypeForTable(new DeviceTypeForTable(extendedData));
		const columnsKey = new Set<string>();
		for (let i = 0; i < data.length; i++) {
			const device = data[i];
			Object.keys(device.attributes).forEach((columnsNames: string) => {
				columnsKey.add(columnsNames);
			});
		}
		const newColumnsDefinition = [...tableColumnHeaderConfiguration];
		columnsKey.forEach((c: string) => {
			newColumnsDefinition.push({
				id: `${c}`,
				label: c,
				align: 'left',
				/**
				 * Function to render the column
				 * @param rowData The row data
				 * @returns The rendered column element.
				 */
				render: (rowData) => {
					return (
						<span className={styles.coldNeutralColor}>{rowData.data[c]}</span>
					);
				},
			});
		});
		newColumnsDefinition.push({
			id: 'linkToDeviceShadow',
			label: '',
			align: 'left',
			/**
			 * Function to render the column
			 * @param rowData The row data
			 * @returns The rendered column element.
			 */
			render: (rowData) => {
				if (has_permission(user, ['Admin'])) {
					const pilabsSerialNumber = rowData.data.pilabsSerialNumber;
					const url = `https://eu-west-1.console.aws.amazon.com/iot/home?region=eu-west-1#/thing/${pilabsSerialNumber}/namedShadow/Classic%20Shadow`;
					return (
						<Tooltip title="Open thing shadow in a new tab">
							<a href={url} target="_blank" rel="noopener noreferrer">
								<OpenInNewIcon style={{ color: 'black' }} fontSize="small" />
							</a>
						</Tooltip>
					);
				}
				return null;
			},
		});
		setColumnsDefinition(newColumnsDefinition);
	}

	useEffect(() => {
		if (devices == null) {
			listDevice()
				.then((data) => {
					setDevices(data);
				})
				.catch((err) => {
					if (err.response.data.message) {
						setSearchError(err.response.data.message);
					} else {
						setSearchError('Problem loading devices!');
					}
					setIsLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		createDisplayTable(devices || [], user);
		if (devices !== undefined && devices !== null) {
			setIsLoading(false);
		}
	}, [devices, user]);

	/**
	 * On submit search event.
	 * @param query Query string to filter the devices.
	 */
	const search = (query: string) => {
		clearSelectedRows();
		setIsLoading(true);
		setSearchError(undefined);
		setFilter('');
		listDevice(query)
			.then((data) => {
				setDevices(data);
			})
			.catch((err) => {
				if (err.response.data.message) {
					setSearchError(err.response.data.message);
				} else {
					setSearchError('Invalid Search Query!');
				}
				setIsLoading(false);
			});
	};

	/**
	 * Resets the page without search and filter
	 */
	const resetSearch = () => {
		setFilter('');
		search('');
		setSearchError(undefined);
		if (typeof searchRef.current?.reset === 'function') {
			searchRef.current.reset();
		}
	};

	/**
	 * Handles the change in row selection.
	 * @param ids - An array of selected row IDs.
	 */
	const onRowSelectionChange = useCallback(
		(ids: readonly number[]) => {
			if (devices) {
				const selectedSNs = ids.map((id) => devices[id].pilabsSerialNumber);
				setSelectedSerialNumbers(selectedSNs);
			}
		},
		[devices],
	);

	/**
	 * Handles the event when the modal for assigning devices to a customer is closed.
	 */
	const handleCancelAssignDevices = () => {
		setAssignDevicesOpen(false);
	};

	/**
	 * Handles the event when the modal for assigning devices to a customer is closed.
	 */
	const handleCancelFilterDevices = () => {
		setFilterDevicesOpen(false);
	};

	/**
	 *
	 * @param serialNumbers list of serial numbers to filter devices
	 */
	const handleFilter = (serialNumbers: string) => {
		setIsLoading(true);
		setSearchError(undefined);
		setFilterDevicesOpen(false);
		clearSelectedRows();
		// Resets the search field component without calling the backend
		if (typeof searchRef.current?.reset === 'function') {
			searchRef.current.reset();
		}
		listDevice()
			.then((data) => {
				const allDevices: Device[] = data;
				if (!serialNumbers) {
					setDevices(data);
					return;
				}
				const serialNumbersList = serialNumbers.split('\n');
				const filteredDevices = allDevices?.filter((device: Device) => {
					return serialNumbersList.includes(device.pilabsSerialNumber);
				});
				setDevices(filteredDevices);
				setFilter(serialNumbers);
			})
			.catch((err) => {
				if (err.response.data.message) {
					setSearchError(err.response.data.message);
				} else {
					setSearchError('Problem loading devices!');
				}
				setIsLoading(false);
			});
	};

	/**
	 * Handles the event when the devices are assigned to a customer.
	 * @param customer The customer which the devices were assigned
	 * @param serialNumbers The serial numbers of the devices assigned to a customer.
	 * @param endpointRegionLabel The label of the endpoint region.
	 */
	const handleAssignDevices = (
		customer: Customer,
		serialNumbers: readonly string[],
		endpointRegionLabel: string,
	) => {
		serialNumbers.forEach((serial) => {
			const updatedDevice = deviceTypeForTable.deviceList.find(
				(device) => device.pilabsSerialNumber === serial,
			);
			if (updatedDevice) {
				updatedDevice.customer = customer.name;
				updatedDevice.endpointRegionLabel = endpointRegionLabel;
			}
		});
		clearSelectedRows();
		setAssignDevicesOpen(false);
	};

	/**
	 * Clears the selected rows in the table
	 */
	const clearSelectedRows = () => {
		if (typeof tableRef.current?.clearSelectedRows === 'function') {
			tableRef.current.clearSelectedRows();
		}
	};

	// Check if the data is not loading and there are no devices
	// then set hiddenTable to true; otherwise, set it to false.
	const hiddenTable =
		!isLoading && devices && devices.length === 0 ? true : false;

	return (
		<>
			<div className={styles.title}>Inventory</div>
			<div className={styles.searchFieldContainer}>
				<div className={styles.searchField}>
					<SearchTextField
						id="search"
						label="Search"
						helperText={searchError}
						onSubmitSearch={search}
						ref={searchRef}
						disabled={isLoading}
						size={'small'}
					/>
				</div>
				<div>
					<Button
						className="height-56"
						text="Filter"
						variation={filter ? 'success' : 'action'}
						onClick={() => setFilterDevicesOpen(true)}
						endIcon={<FilterAltOutlinedIcon />}
						disabled={isLoading}
					/>
				</div>
				<Button
					text={'Reset'}
					variation={'ghost'}
					className={styles.resetButton}
					startIcon={<BiX />}
					onClick={resetSearch}
					disabled={isLoading}
				/>
			</div>
			<div hidden={hiddenTable}>
				<Table
					title={''}
					columnsConfig={columnsDefinition}
					data={deviceTypeForTable.deviceList}
					isLoading={isLoading}
					allowSelectRows={true}
					selectedRowToolbarIcon={<></>}
					selectedRowToolbarIconLabel={''}
					rowsPerPageOptions={[10, 25, 50, 100]}
					defaultRowsPerPage={10}
					disablePagination={false}
					showBorder={true}
					dense={false}
					densityControl={undefined}
					rowActions={[]}
					defaultOrderBy={'pilabsSerialNumber'}
					defaultOrder={undefined}
					maxTableContainerHeight={'calc( 100% - 207px )'}
					customToolbar={
						<Button
							text={'Assign to Customer'}
							size={'small'}
							startIcon={<ExitToAppIcon />}
							onClick={() => setAssignDevicesOpen(true)}
							disabled={
								!selectedSerialNumbers || selectedSerialNumbers.length === 0
							}
							variation={'action'}
						></Button>
					}
					onRowSelectionChange={onRowSelectionChange}
					ref={tableRef}
				/>
				{assignDevicesOpen ? (
					<AssignDevicesToCustomer
						open={assignDevicesOpen}
						serialNumbers={selectedSerialNumbers}
						onCancel={handleCancelAssignDevices}
						onAssign={handleAssignDevices}
					/>
				) : null}

				{filterDevicesOpen ? (
					<FilterDevices
						open={filterDevicesOpen}
						filter={filter}
						onSearch={handleFilter}
						onCancel={handleCancelFilterDevices}
					/>
				) : null}
			</div>
			<div className="row" hidden={!hiddenTable}>
				<div className="col">
					<p>Sorry, your search returned no results.</p>
				</div>
			</div>
		</>
	);
};
export default Inventory;
