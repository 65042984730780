import React, { ReactNode, useState } from 'react';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableCell from '@mui/material/TableCell';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiCollapse from '@mui/material/Collapse';
import MuiBox from '@mui/material/Box';
import MuiIconButton from '@mui/material/IconButton';
import MuiTooltip from '@mui/material/Tooltip';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dateFormat from 'dateformat';
import { ColumnConfig, RowAction, RowData } from './Table';

export type TableBodyRowProps<DataType> = {
	/** The configuration for the table columns. */
	columnsConfig: readonly ColumnConfig<DataType>[];
	/** The identifiers for the currently selected table rows. */
	selectedRows: readonly number[];
	/** The data to be displayed by this row. */
	rowData: RowData<DataType>;
	/** Whether table rows can be selected. */
	allowSelectRows: boolean;
	/** Whether the table rows can be collapsed. */
	allowCollapsibleRows: boolean;
	/** An optional function that specifies how to render a collapsible row. Required when setting __allowCollapsibleRows__ to __true__. */
	renderCollapsibleRow:
		| ((rowData: RowData<DataType>) => ReactNode | string)
		| null;
	/**
	 * Whether the collapsible button will be on the first (after row selection toggle) or the last column.
	 * @default last
	 */
	collapsibleButtonColumnPosition?: 'first' | 'last';
	/** A callback function for a table row is selected or unselected. */
	onToggleSelectRow: (event: React.MouseEvent<unknown>, rowId: number) => void;
	/** A list of icon buttons to be displayed at the end of each row. */
	rowActions: RowAction<DataType>[];
};

export const TableBodyRow = <DataType,>({
	columnsConfig,
	selectedRows,
	rowData,
	allowSelectRows,
	allowCollapsibleRows,
	renderCollapsibleRow,
	collapsibleButtonColumnPosition = 'last',
	onToggleSelectRow,
	rowActions,
}: TableBodyRowProps<DataType>) => {
	const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
	const [isRowCollapsed, setIsRowCollapsed] = useState<boolean>(true);

	const isRowSelected = (id: number) => selectedRows.indexOf(id) !== -1;

	const isItemSelected = isRowSelected(rowData.id);
	const labelId = `enhanced-table-checkbox-${rowData.id}`;
	const collapsibleRowColumnSpan =
		columnsConfig.length +
		(allowSelectRows ? 1 : 0) +
		1 +
		(rowActions.length > 0 ? 1 : 0);

	const handleOnToggleSelectRow =
		(rowId: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
			if (allowSelectRows) {
				onToggleSelectRow(event, rowId);
			}
		};

	const formatCellValue = (
		value: any,
		headCell: ColumnConfig<DataType>,
	): any => {
		if (value instanceof Date) {
			return dateFormat(value, headCell.dateTimeFormat || 'dd/mm/yyyy');
		}
		return value;
	};

	const handleToggleIsRowCollapsed = () => {
		setIsRowCollapsed(!isRowCollapsed);
	};

	const collapsibleButton = (
		<MuiTableCell padding="checkbox">
			<MuiIconButton
				aria-label="expand row"
				size="small"
				onClick={handleToggleIsRowCollapsed}
			>
				{isRowCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
			</MuiIconButton>
		</MuiTableCell>
	);
	return (
		<>
			<MuiTableRow
				aria-checked={isItemSelected}
				tabIndex={-1}
				role="checkbox"
				hover
				selected={isItemSelected}
				sx={{
					'& > *': { borderBottom: 'unset !important' },
					cursor: allowCollapsibleRows ? 'pointer' : 'default',
				}}
				onMouseEnter={() => setIsRowHovered(true)}
				onMouseLeave={() => setIsRowHovered(false)}
				onClick={() => {
					if (allowCollapsibleRows) {
						handleToggleIsRowCollapsed();
					}
				}}
			>
				{/* Column for the checkbox for row selection */}
				{allowSelectRows && (
					<MuiTableCell padding="checkbox">
						<MuiCheckbox
							color="primary"
							checked={isItemSelected}
							inputProps={{
								'aria-labelledby': labelId,
							}}
							onClick={handleOnToggleSelectRow(rowData.id)}
						/>
					</MuiTableCell>
				)}

				{allowCollapsibleRows &&
					collapsibleButtonColumnPosition === 'first' &&
					collapsibleButton}

				{/* Data columns */}
				{columnsConfig.map(
					(headCell: ColumnConfig<DataType>, columnIndex: number) => {
						const { paddingLeft, paddingRight } = headCell;
						const cssPadding = { paddingLeft, paddingRight };
						return (
							<MuiTableCell
								key={columnIndex}
								align={headCell.align || 'left'}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								style={headCell.disablePadding ? undefined : cssPadding}
								width={headCell.width}
							>
								{headCell.render
									? headCell.render(rowData)
									: formatCellValue(rowData.data[headCell.id], headCell)}
							</MuiTableCell>
						);
					},
				)}

				{/* Column for the icon to toggle the collapsible rows */}
				{allowCollapsibleRows &&
					collapsibleButtonColumnPosition === 'last' &&
					collapsibleButton}

				{/* Column for the row action icons */}
				{rowActions.length > 0 && (
					<MuiTableCell padding="checkbox" style={{ whiteSpace: 'nowrap' }}>
						{rowActions.map((rowAction: RowAction<DataType>, index: number) => {
							const hideIconButton =
								!!rowAction.showOnlyOnRowHover && !isRowHovered;
							return (
								<MuiTooltip key={index} title={rowAction.tooltip || ''}>
									<MuiIconButton
										size="small"
										onClick={() => rowAction.onClick(rowData)}
										style={hideIconButton ? { visibility: 'hidden' } : {}}
									>
										{rowAction.icon}
									</MuiIconButton>
								</MuiTooltip>
							);
						})}
					</MuiTableCell>
				)}
			</MuiTableRow>

			{/* The collapsible row */}
			<MuiTableRow selected={isItemSelected}>
				<MuiTableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={collapsibleRowColumnSpan}
				>
					<MuiCollapse in={!isRowCollapsed} timeout="auto" unmountOnExit>
						<MuiBox sx={{ margin: 1 }}>
							{renderCollapsibleRow ? renderCollapsibleRow(rowData) : null}
						</MuiBox>
					</MuiCollapse>
				</MuiTableCell>
			</MuiTableRow>
		</>
	);
};
