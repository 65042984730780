// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MC5Hx6lD0zF1V6YEPdf9{width:100%;max-width:100%;height:100%;display:flex;flex-direction:row;justify-content:center}.GIQzG2t6l2kvyVr7_xHy{width:calc(100% - 40px);max-width:calc(100% - 40px);height:100%;margin:0 20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MC5Hx6lD0zF1V6YEPdf9",
	"maxWidth": "GIQzG2t6l2kvyVr7_xHy"
};
export default ___CSS_LOADER_EXPORT___;
