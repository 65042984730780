import { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { ColumnConfig, RowData, Table } from '@easerill/pixida-group-ui';
import styles from '../DeviceDetails/DeviceDetails.module.scss';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
//import { BiErrorCircle } from 'react-icons/bi';
import React from 'react';
import useStateEvent from '../../hooks/state/useStateEvent';
import { Event } from '@farm/types/event';
import { EventForTable, EventTypeForTable } from './EventForTable';
import { listEvents } from '../../api/events';
import EventDetail from './EventDetail';

const tableColumnHeaderConfiguration: ColumnConfig<EventForTable>[] = [
	{
		id: 'serialNumber',
		label: 'Serial Number',
		align: 'left',
		/**
		 * Function to render the column
		 * @param rowData The row data
		 * @returns The rendered column element.
		 */
		render: (rowData) => {
			return (
				<span className={styles.coldNeutralColor}>
					{rowData.data.serialNumber}
				</span>
			);
		},
	},
	{
		id: 'date',
		label: 'Date',
		align: 'left',
	},
	{
		id: 'title',
		label: 'Title',
		align: 'left',
	},
];

/**
 * Events component - Display a list of OTA events by device
 * @returns DeviceDetails component
 */
const DeviceDetails = () => {
	const params = useParams();
	const [columnsDefinition, setColumnsDefinition] = useState<
		ColumnConfig<EventForTable>[]
	>(tableColumnHeaderConfiguration);
	const [eventTypeForTable, setEventTypeForTable] = useState<EventTypeForTable>(
		new EventTypeForTable([]),
	);
	const [isLoading, setIsLoading] = useState(true);
	const { events: events, setEvents: setEvents } = useStateEvent();

	useEffect(() => {
		if (!events && params.deviceId) {
			listEvents(params.deviceId)
				.then((events: Event[] | null) => {
					setEvents(events);
					setIsLoading(false);
				})
				.catch((err: any) => {
					console.log(err);
					setIsLoading(false);
				});
		}
	}, []);

	useEffect(() => {
		createDisplayTable(events || []);
		if (events !== undefined && events !== null) {
			setIsLoading(false);
		}
	}, [events]);

	/**
	 * Create the device table
	 * @param data the list of the devices
	 */
	function createDisplayTable(data: Event[]) {
		setEventTypeForTable(new EventTypeForTable(data));
		const columnsKey = new Set<string>();
		const newColumnsDefinition = [...tableColumnHeaderConfiguration];
		if (columnsKey.size > 0) {
			columnsKey.forEach((c: string) => {
				newColumnsDefinition.push({
					id: `${c}`,
					label: c,
					align: 'left',
					/**
					 * Function to render the column
					 * @param rowData The row data
					 * @returns The rendered column element.
					 */
					render: (rowData) => {
						return (
							<span className={styles.coldNeutralColor}>{rowData.data[c]}</span>
						);
					},
				});
			});
		}
		setColumnsDefinition(newColumnsDefinition);
	}

	/**
	 *
	 * @param rowData the row data
	 * @returns ReactNode
	 */
	const renderCollapsibleRow = (rowData: RowData<EventForTable>) => {
		return <EventDetail event={rowData} />;
	};

	return (
		<>
			<Paper elevation={3} className={styles.content}>
				<div className={styles.title}>Details</div>
				<div className={styles.deviceId}>{params.deviceId}</div>
				<Grid container rowSpacing={0} spacing={0} className={styles.grid}>
					<Grid xs={6} className={styles.gridItem}>
						<div className={styles.propertyName}>ID</div>
						<div className={styles.propertyValueDeviceId}>
							{params.deviceId}
						</div>
					</Grid>
				</Grid>
				{/*<Grid xs={6} className={styles.gridItem}>
						<div className={styles.propertyName}>Current Status</div>
						<div className={styles.propertyValueStatus}>
							<BiErrorCircle className={styles.icon} style={{ color: 'red' }} />
							<span style={{ marginLeft: '5px' }}>Issue</span>
						</div>
					</Grid>
				</Grid>
				<Grid container rowSpacing={0} spacing={0} className={styles.grid}>
					<Grid xs={4} className={styles.gridItem}>
						<div className={styles.propertyName}>Device</div>
						<div className={styles.propertyValue}>SafetyTag</div>
					</Grid>
					<Grid xs={4} className={styles.gridItem}>
						<div className={styles.propertyName}>Customer</div>
						<div className={styles.propertyValue}>MVG</div>
					</Grid>
					<Grid xs={4} className={styles.gridItem}>
						<div className={styles.propertyName}>Version</div>
						<div className={styles.propertyValue}>v-4221606a-1.1.2</div>
					</Grid>
				</Grid>
				<Grid container rowSpacing={0} spacing={0} className={styles.grid}>
					<Grid xs={12} className={styles.gridItem}>
						<div className={styles.propertyName}>Description</div>
						<div className={styles.propertyValue}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
							reprehenderit in voluptate velit esse cillum dolore eu fugiat
							nulla pariatur. Excepteur sint occaecat cupidatat non proident,
							sunt in culpa qui officia deserunt mollit anim id est laborum.
						</div>
					</Grid>
				</Grid>
				*/}
				<div className={styles.grid}>
					<Table
						title={''}
						columnsConfig={columnsDefinition}
						data={eventTypeForTable.eventsList}
						allowCollapsibleRows={true}
						renderCollapsibleRow={renderCollapsibleRow}
						isLoading={isLoading}
						allowSelectRows={true}
						selectedRowToolbarIcon={<></>}
						selectedRowToolbarIconLabel={''}
						rowsPerPageOptions={[10, 25, 50, 100]}
						defaultRowsPerPage={10}
						disablePagination={false}
						showBorder={true}
						dense={false}
						densityControl={undefined}
						rowActions={[]}
						defaultOrderBy={'id'}
						defaultOrder={undefined}
						maxTableContainerHeight={'calc( 100% - 207px )'}
					/>
				</div>
			</Paper>
		</>
	);
};

export default DeviceDetails;
