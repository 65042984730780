import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { eventsSlice } from '../../redux/Events';
import { Event } from '@farm/types/event';

/**
 * The object returned by `useStateDevice`
 */
type ReturnType = {
	events: Event[] | null | undefined;
	// eslint-disable-next-line no-unused-vars
	setEvents: (events: Event[] | null) => void;
};

/**
 * Custom hook for handling Auth data in the Redux state.
 * @returns See `ReturnType`.
 */
const useStateEvent = (): ReturnType => {
	const dispatch = useDispatch();

	const events = useSelector<RootState, Event[] | null | undefined>(
		(state: RootState) => state.event.events,
	);

	/**
	 * Set the events in the Redux store.
	 * @param events - An array of Device objects or null if there are no devices.
	 */
	const setEvents = (events: Event[] | null) => {
		dispatch(eventsSlice.actions.setEvents(events));
	};

	return {
		events: events,
		setEvents: setEvents,
	};
};

export default useStateEvent;
