// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zUJm9V8uDGY_uhd97CUt{width:100%;min-height:1.5em;display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-between;align-items:center}.uEi1WgWfYJ0CH79NoWKa{width:100%}.jW7lmftgEXB8q0GQc_YE{margin-left:1em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "zUJm9V8uDGY_uhd97CUt",
	"progressBar": "uEi1WgWfYJ0CH79NoWKa",
	"label": "jW7lmftgEXB8q0GQc_YE"
};
export default ___CSS_LOADER_EXPORT___;
