import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Region } from '@farm/types/region';

/**
 * The state of region list
 */
export type RegionsState = {
	regions: Region[] | null | undefined;
};

export const regionsInitialState: RegionsState = {
	regions: undefined,
};

export const regionsSlice = createSlice({
	name: 'regions',
	initialState: regionsInitialState as RegionsState,
	reducers: {
		/**
		 * Sets devices in the Redux store state.
		 * @param state - Current state of devices in the Redux store.
		 * @param action - Redux action containing devices data or null.
		 * @returns New state with updated devices information.
		 */
		setRegions: (
			state: RegionsState,
			action: PayloadAction<Region[] | null>,
		): RegionsState => ({
			...state,
			regions: action.payload,
		}),
	},
});
