import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Device } from '@farm/types/device';

/**
 * The state of device list
 */
export type DevicesState = {
	devices: Device[] | null | undefined;
};

export const devicesInitialState: DevicesState = {
	devices: undefined,
};

export const devicesSlice = createSlice({
	name: 'devices',
	initialState: devicesInitialState as DevicesState,
	reducers: {
		/**
		 * Sets devices in the Redux store state.
		 * @param state - Current state of devices in the Redux store.
		 * @param action - Redux action containing devices data or null.
		 * @returns New state with updated devices information.
		 */
		setDevices: (
			state: DevicesState,
			action: PayloadAction<Device[] | null>,
		): DevicesState => ({
			...state,
			devices: action.payload === null ? null : [...action.payload],
		}),
	},
});
