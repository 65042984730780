import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Event } from '@farm/types/event';

/**
 * The state of device list
 */
export type EventsState = {
	events: Event[] | null | undefined;
};

export const eventsInitialState: EventsState = {
	events: undefined,
};

export const eventsSlice = createSlice({
	name: 'events',
	initialState: eventsInitialState as EventsState,
	reducers: {
		/**
		 * Sets events in the Redux store state.
		 * @param state - Current state of events in the Redux store.
		 * @param action - Redux action containing events data or null.
		 * @returns New state with updated events information.
		 */
		setEvents: (
			state: EventsState,
			action: PayloadAction<Event[] | null>,
		): EventsState => ({
			...state,
			events: action.payload === null ? null : [...action.payload],
		}),
	},
});
