import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { Device } from '@farm/types/device';
import { devicesSlice } from '../../redux/Inventory';

/**
 * The object returned by `useStateDevice`
 */
type ReturnType = {
	devices: Device[] | null | undefined;
	// eslint-disable-next-line no-unused-vars
	setDevices: (devices: Device[] | null) => void;
};

/**
 * Custom hook for handling Auth data in the Redux state.
 * @returns See `ReturnType`.
 */
const useStateDevice = (): ReturnType => {
	const dispatch = useDispatch();

	const devices = useSelector<RootState, Device[] | null | undefined>(
		(state: RootState) => state.device.devices,
	);

	/**
	 * Set the devices in the Redux store.
	 * @param device - An array of Device objects or null if there are no devices.
	 */
	const setDevices = (device: Device[] | null) => {
		dispatch(devicesSlice.actions.setDevices(device));
	};

	return {
		devices,
		setDevices,
	};
};

export default useStateDevice;
