// eslint-disable-next-line filenames/match-exported
import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { refreshAuth } from './auth';

/* REQUEST INTERCEPTOR */
axios.interceptors.request.use((request) => {
	if (
		request.url?.endsWith('oauth2/token') ||
		request.url?.endsWith('login') ||
		request.url?.endsWith('refresh')
	) {
		return request;
	}
	const token = window.localStorage.getItem('idToken');
	const decodedToken = token ? jwtDecode<JwtPayload>(token) : null;
	if (
		decodedToken &&
		decodedToken.exp &&
		decodedToken.exp > (new Date().getTime() + 1) / 1000
	) {
		/* eslint-disable @typescript-eslint/ban-ts-comment */
		// @ts-ignore
		request.headers.Authorization = `Bearer ${token}`;
		/* eslint-enable @typescript-eslint/ban-ts-comment */
	} else {
		return (
			refreshAuth()
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.then(({ idToken, accessToken }: any) => {
					/* eslint-disable @typescript-eslint/ban-ts-comment */
					// @ts-ignore
					request.headers.Authorization = `Bearer ${idToken}`;
					/* eslint-enable @typescript-eslint/ban-ts-comment */
					if (request.url?.endsWith('logout')) {
						request.data.idToken = idToken;
						request.data.accessToken = accessToken;
					}
					return request;
				})
				.catch((error) => {
					throw error;
				})
		);
	}
	return request;
});

/* RESPONSE INTERCEPTOR */
axios.interceptors.response.use((response) => {
	// Add interceptions to responses here.
	return response;
});

export default axios;
