import axios from './interceptors';
import { BASE_URL } from './constants';

/**
 * Calls the API to retrieve the customer list.
 * @returns a list of devices
 */
export const listCustomers = async () => {
	const response = await axios.get(`${BASE_URL}/v1/customers`);
	return response.data;
};

/**
 * Assigns devices to a customer.
 * @param customerId - The ID of the customer to whom devices will be assigned.
 * @param serialNumbers - An array of serial numbers of devices to be assigned.
 * @param region - The endpoint region of the devices.
 */
export const assignDevicesToCustomer = async (
	customerId: string,
	serialNumbers: readonly string[],
	region: string,
) => {
	await axios.patch(`${BASE_URL}/v1/customers/${customerId}`, {
		serialNumbers,
		region,
	});
};
