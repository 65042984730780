import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { regionsSlice } from '../../redux/Regions';
import { Region } from '@farm/types/region';

/**
 * The object returned by `useStateRegion`
 */
type ReturnType = {
	regions: Region[] | null | undefined;
	// eslint-disable-next-line no-unused-vars
	setRegions: (customers: Region[] | null) => void;
};

/**
 * Custom hook for handling customer data in the Redux state.
 * @returns See `ReturnType`.
 */
const useStateRegion = (): ReturnType => {
	const dispatch = useDispatch();

	const regions = useSelector<RootState, Region[] | null | undefined>(
		(state: RootState) => state.region.regions,
	);

	/**
	 * Set the regions in the Redux store.
	 * @param regions - An array of Customer objects or null if there are no devices.
	 */
	const setRegions = (regions: Region[] | null) => {
		dispatch(regionsSlice.actions.setRegions(regions));
	};

	return {
		regions,
		setRegions,
	};
};

export default useStateRegion;
