import React, { useEffect } from 'react';
import {
	Button,
	Modal,
	ModalProps,
	useSnackbar,
} from '@easerill/pixida-group-ui';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PublicIcon from '@mui/icons-material/Public';
import {
	TextField,
	InputAdornment,
	Backdrop,
	CircularProgress,
	Autocomplete,
} from '@mui/material';
import { listCustomers, assignDevicesToCustomer } from '../../api/customer';
import useStateCustomer from '../../hooks/state/useStateCustomer';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Customer } from '@farm/types/customer';
import { Region } from '@farm/types/region';
import useStateRegion from '../../hooks/state/useStateRegion';

/**
 * Props for the AssignDevicesToCustomer component.
 */
export type AssignDevicesToCustomerProps = {
	serialNumbers: readonly string[];
	onCancel: () => void;
	/* eslint-disable no-unused-vars */
	onAssign: (
		customer: Customer,
		serialNumbers: readonly string[],
		endpointRegionLabel: string,
	) => void;
	/* eslint-enable no-unused-vars */
} & Omit<ModalProps, 'title' | 'onClose'>;

/**
 * Component to assign devices to a customer.
 * @param {AssignDevicesToCustomerProps} props - The props for the AssignDevicesToCustomer component.
 * @returns The AssignDevicesToCustomer component.
 */
const AssignDevicesToCustomer = ({
	onCancel,
	onAssign,
	open,
	serialNumbers,
}: AssignDevicesToCustomerProps) => {
	const { customers, setCustomers } = useStateCustomer();
	const [customer, setCustomer] = React.useState<Customer | null>(null);
	const { regions, setRegions } = useStateRegion();
	const [region, setRegion] = React.useState<Region | null>(null);
	const [loading, setLoading] = React.useState(false);
	const { triggerSnackbar } = useSnackbar();

	useEffect(() => {
		if (open) {
			setLoading(true);
			listCustomers()
				.then((data) => {
					setCustomers(data.customers);
					setRegions(data.regions);
				})
				.catch(() => {
					triggerSnackbar('Error on loading customers', 'error');
				})
				.finally(() => setLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	/**
	 * Handles the click event when the user cancels the assignment process.
	 */
	const handleCancelClick = () => {
		if (onCancel) {
			onCancel();
		}
	};

	/**
	 * Handles the click event when the user confirms the assignment of devices to the selected customer.
	 */
	const handleAssignClick = () => {
		if (!customer || !region) {
			// Is not necessary to display an error because the Assign is enabled
			// only after the user selects a customer and a region.
			return;
		}

		setLoading(true);

		assignDevicesToCustomer(customer.id, serialNumbers, region.name)
			.then(() => {
				triggerSnackbar(
					`Successfully assigned ${serialNumbers.length} ${
						serialNumbers.length === 1 ? 'device' : 'devices'
					} to customer ${customer.name}`,
					'success',
				);
				if (onAssign) {
					onAssign(customer, serialNumbers, region.label);
				}
			})
			.catch(() => {
				triggerSnackbar(
					`There was an error assigning devices to customer ${customer.name}. Please check your email for details.`,
					'error',
				);
			})
			.finally(() => setLoading(false));
	};

	return (
		<Modal open={open} onClose={handleCancelClick} title="Assign to Customer">
			<Backdrop
				sx={{
					color: '#fff',
					/**
					 * Sets the z-order of a positioned element
					 * @param {object} theme - The MUI theme object.
					 * @returns {number} The calculated z-index value for the backdrop.
					 */
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Autocomplete
				id="customerId"
				options={Object.freeze(customers ?? [])}
				getOptionLabel={(option) => option.name}
				value={customers?.find((c) => c.id === customer?.id) || null}
				onChange={(event, customer) => {
					setCustomer(customer);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Customer"
						placeholder="Select Customer"
						InputProps={{
							...params.InputProps,
							startAdornment: !customer ? (
								<InputAdornment position="start">
									<PersonOutlineIcon sx={{ marginRight: '5px' }} />
								</InputAdornment>
							) : null,
						}}
					/>
				)}
			/>
			<Autocomplete
				id="region"
				options={Object.freeze(regions ?? [])}
				getOptionLabel={(option) => option.label}
				value={regions?.find((r: Region) => r.name === region?.name) || null}
				onChange={(event, region) => {
					setRegion(region);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Endpoint Region"
						placeholder="Select Endpoint Region"
						InputProps={{
							...params.InputProps,
							startAdornment: !region ? (
								<InputAdornment position="start">
									<PublicIcon sx={{ marginRight: '5px' }} />
								</InputAdornment>
							) : null,
						}}
					/>
				)}
			/>
			<div style={{ marginLeft: 'auto', display: 'flex', gap: '16px' }}>
				<Button
					variation="info"
					text="Cancel"
					onClick={handleCancelClick}
					startIcon={<HighlightOffIcon />}
				/>
				<Button
					variation="success"
					text="Assign"
					onClick={handleAssignClick}
					startIcon={<CheckCircleOutlineIcon />}
					disabled={!customer || !region}
				/>
			</div>
		</Modal>
	);
};

export default AssignDevicesToCustomer;
