import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
//import MainScreen from '../screens/MainScreen/MainScreen';
import { User } from '../redux/Auth';
import useStateAuth from '../hooks/state/useStateAuth';
import useCustomerHive from '../hooks/api/useCustomerHive';
import { useDispatch } from 'react-redux';
import Inventory from '../screens/MainScreen/Inventory';
import { Routes as PixidaRoutes } from '@easerill/pixida-group-ui';
import DeviceDetails from '../screens/DeviceDetails/DeviceDetails';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './AppRoute.module.scss';

/* USER ROLES */
const ROOT = 'Root';
const ADMIN = 'Admin';
const FLEET_MANAGER = 'FleetManager';

export const routes: PixidaRoutes = {
	inventory: {
		uri: '/inventory',
		label: 'Inventory',
		roles: [ROOT, ADMIN, FLEET_MANAGER],
		account: 'pilabs',
	},
};

/**
 * The routes of the application.
 * @returns An element representing the routes of the application.
 */
const AppRoutes = () => {
	const { user, setUser } = useStateAuth();
	const { authenticate, clearLocationUrl } = useCustomerHive();
	const dispatch = useDispatch();

	useEffect(() => {
		if (user === undefined || user === null) {
			authenticate().then((authenticatedUser: User | null) => {
				setUser(authenticatedUser);
				clearLocationUrl();
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, dispatch]);

	if (user === undefined) {
		return (
			<div className={styles.loadingContainer}>
				<CircularProgress size={100} />
			</div>
		);
	}
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Navigate to="/inventory" replace />} />
				<Route
					path="/inventory"
					element={
						<ProtectedRoute
							currentRoute={routes.inventory}
							userAccount={user?.account}
							routes={routes}
							userRoles={user?.groups || []}
							element={<Inventory />}
							breadcrumbList={[routes.inventory]}
						/>
					}
				/>
				<Route
					path="/inventory/:deviceId"
					element={
						<ProtectedRoute
							currentRoute={routes.inventory}
							userAccount={user?.account}
							routes={routes}
							breadcrumbList={[
								routes.inventory,
								{
									label: 'Details - :deviceId',
									roles: routes.inventory.roles,
									uri: `${routes.inventory.uri}/:deviceId`,
								},
							]}
							userRoles={user?.groups || []}
							element={<DeviceDetails />}
						/>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
};

export default AppRoutes;
