import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type User = {
	username?: string | null;
	email: string;
	name: string;
	account?: string;
	groups: Array<string>;
};

export type AuthState = {
	user: User | null | undefined;
};

export const authInitialState: AuthState = {
	user: undefined,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState: authInitialState as AuthState,
	reducers: {
		setUser: (
			state: AuthState,
			action: PayloadAction<User | null>,
		): AuthState => ({
			...state,
			user: action.payload === null ? null : { ...action.payload },
		}),
	},
});
