import { RowData, Button } from '@easerill/pixida-group-ui';
import React from 'react';
import { EventForTable } from './EventForTable';
import { loadEvent } from '../../api/events';
import CloudDownload from '@mui/icons-material/CloudDownload';

/**
 *
 */
type EventDetailProps = {
	event: RowData<EventForTable>;
};

/**
 * Events detail - Display a detailed event of the device
 * @param root0 The event object
 * @param root0.event The event itself
 * @returns EventDetail component
 */
const EventDetail = ({ event: event }: EventDetailProps) => {
	return (
		<>
			<span className="m-2">
				{event?.data.fileKeys?.map((key: string) => {
					const keyArr = key.split('/');
					const filename = keyArr.at(keyArr.length - 1);
					return (
						<>
							<Button
								className="download-btn"
								onClick={() => {
									if (filename) {
										loadEvent(event.data.serialNumber, event.data.id, filename)
											.then((data) => {
												const byteString = atob(data);
												const buffer = new ArrayBuffer(byteString.length);
												const intArray = new Uint8Array(buffer);

												for (let i = 0; i < byteString.length; i++) {
													intArray[i] = byteString.charCodeAt(i);
												}
												const url = window.URL.createObjectURL(
													new Blob([buffer], {
														type: 'application/octet-stream',
													}),
												);
												const link = document.createElement('a');
												link.href = url;
												link.setAttribute('download', filename);
												document.body.appendChild(link);
												link.click();
											})
											.catch((err) => {
												console.log(err);
											});
									}
								}}
								key={key}
								text={filename || ''}
								endIcon={<CloudDownload />}
							/>
							<p>{event.data.description}</p>
						</>
					);
				}) || 'nothing to show'}
			</span>
		</>
	);
};

export default EventDetail;
